<!-- This component is used to wrap the content of the page -->
<template>
  <section :class="containerClass">
    <slot />
  </section>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

const props = defineProps({
  className: null as unknown as PropType<unknown> // 自定义class，亦可用于覆盖默认样式
})

const containerClass = computed(() => {
  return ['com-container', 'com-container-responsive', props.className]
})
</script>
<style lang="scss" scoped>
.com-container {
  --com-container-max-width: 100%;
}
.com-container {
  position: relative;
  //z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: var(--com-container-max-width);
}
.com-container-responsive {
  // min-width: 640px
  @media screen and (min-width: 640px) {
  }
  // min-width: 768px
  @media screen and (min-width: 768px) {
  }
  // min-width: 1024px
  @media screen and (min-width: 1024px) {
    --com-container-max-width: 92%;
  }
  // min-width: 1280px
  @media screen and (min-width: 1280px) {
  }
  // min-width: 1536px
  @media screen and (min-width: 1536px) {
    --com-container-max-width: 1440px;
  }
}
</style>
